import React from "react"

import style from "./job-experience-row.module.css"

export default function JobExperienceRow(props: JobExperienceRowProperties) {
  return (
    <div className="card">
      <div
        className={`card-header ${style.cardHeader}`}
        id={`heading${props.company.replace(/ /g, "")}`}
      >
        <h2
          className="mb-0 position-relative"
          style={{ cursor: "pointer" }}
          data-toggle="collapse"
          data-target={`#collapse${props.company.replace(/ /g, "")}`}
          aria-expanded="false"
          aria-controls={`collapse${props.company.replace(/ /g, "")}`}
        >
          <button className="btn text-left" type="button">
            <h5 className="mb-0">
              {props.position}, {props.company}
            </h5>
            <div>
              {props.start} - {props.end ?? "Present"}
            </div>
          </button>

          <i className={`bi bi-chevron-down ${style.toggleChevron}`} />
        </h2>
      </div>

      <div
        id={`collapse${props.company.replace(/ /g, "")}`}
        className="collapse"
        aria-labelledby={`heading${props.company.replace(/ /g, "")}`}
        data-parent="#jobExperienceAccordion"
      >
        <div className="card-body">
          <ul>
            {props.accomplishments.map((accomplishment, index) => {
              return <li key={index}>{accomplishment}</li>
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

type JobExperienceRowProperties = {
  position: string
  company: string
  start: string
  end?: string
  accomplishments: string[]
}
