import React from "react"
import JobExperienceRow from "../components/job-experience-row"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Resume() {
  let downloadElement = (
    <a href="https://docs.google.com/document/d/1Lcdr1s5qGJba7AlNB73OFbBeLoRUfJwmgYQSTxiEU24/export?format=pdf">
      PDF
    </a>
  )

  let downloadWordElement = (
    <a href="https://docs.google.com/document/d/1Lcdr1s5qGJba7AlNB73OFbBeLoRUfJwmgYQSTxiEU24/export?format=docx">
      Word Document
    </a>
  )

  return (
    <Layout>
      <SEO
        title="Resume"
        description="John VanSickle resume page. A general resume can be downlaoded as a PDF or Microsoft Word document."
      />

      <div className="row d-block">
        <p className="col">
          Download my general resume as a {downloadElement} or a{" "}
          {downloadWordElement}
        </p>
      </div>

      <hr />

      <div className="row">
        <div className="col">
          <h2>Professional Experience</h2>
        </div>
      </div>

      {/* <div className="row">
        <div className="col"> */}
      <div className="accordion" id="jobExperienceAccordion">
        <JobExperienceRow
          position="Senior Software Engineer"
          company="Alteryx"
          start="February 2021"
          accomplishments={[
            "Architect new .NET Web API 2 project for new API endpoints following modern conventions",
            "Full-stack React/JSMVC, .NET Framework Web API 2, and Windows Communication Foundation (WCF)",
            "Communicate directly with critical-account customers to ensure user journeys are met",
            "Travel to India to train and bond with new, global teams",
            "Train developers on our technologies and products",
            "Lead team implementing new features",
            "Lead SPIKEs and recommendations for migrating WCF API to ASP.NET API",
            "Improve front-end automated testing architecture using Test Cafe",
            "Fix project READMEs to be up-to-date and include improved workflow instructions",
            "Advocating investing in technical debt toward cloud solutions",
            "Implement new features for Server product",
            "Provide developer feedback throughout the agile process",
          ]}
        />

        <JobExperienceRow
          position="Senior Developer"
          company="CEI"
          start="December 2017"
          end="February 2021"
          accomplishments={[
            "Ported a Windows Presentation Foundation voice transcription app to Xamarin.Forms for macOS",
            "Created Google Chrome browser extension for facilitating voice transcription and injection into web pages using React and Redux",
            "Deploy pre-existing C# libraries as an ASP.NET web API via Docker containers for altering user settings",
            "Utilize Microsoft Azure Functions, Custom Vision, and Queues to make an application suite backend which would be used to detect and report available parking spots in parking lots; frontend apps written using C# Forms and Angular",
            "Implement React test suites across multiple projects (Jest, react-testing-library)",
            "Rewrote tax lookup and payment AngularJS application using Angular9",
          ]}
        />

        <JobExperienceRow
          position="Co-Founder and Developer"
          company="PinPoint Transit LLC"
          start="May 2015"
          end="August 2019"
          accomplishments={[
            "Created web applications that could track and report real-time location of public transit vehicles in Clarion, PA",
            "Published iOS and Android application wrappers of a web application for transit passengers to view the location of transit buses and get ETAs for the bus to any given pickup location",
            "Web Server written using Java and mySQL",
            "Frontend written using JavaScript with HTML",
            "Won awards for best project in college Honors Program",
            "Used by Clarion Area Transit Authority until I decided to end the company and software",
          ]}
        />

        <JobExperienceRow
          position="Software Engineer"
          company="Dataforma"
          start="May 2014"
          end="December 2017"
          accomplishments={[
            "Implemented new patterns and technologies for a 15 year old software platform",
            "Introduced Hibernate ORM framework, replacing custom in-house solution",
            "Supported transition to Kotlin as a supplement for still needing to use Java 6",
            "Succeeded in solo and team projects",
            "Mentored and managed interns and intern projects",
            "Employed after a six month internship",
          ]}
        />
      </div>
      {/* </div>
      </div> */}
      <hr />

      <div className="row">
        <div className="col">
          <h2>Education and Certifications</h2>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ul>
            <li>
              Bachelor of Science in Computer Science, Honors; Clarion
              University of Pennsylvania
            </li>
            <li>Microsoft Cerfified Solutions Developer: App Builder</li>
          </ul>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col">
          <h2>Community Involvement</h2>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ul>
            <li>Co-Organizer of Pittsburgh .NET User Group</li>
            <li>Speaker at Microsoft Global Azure Bootcamp</li>
            <li>Xamarin.Forms Open Source contributor</li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}
